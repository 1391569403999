<script>
import searchFormMixin from '@maison/mixins/searchFormMixin';
import { PhxIcon } from '@phx-styleguides/als';

export default {
  components: { PhxIcon },

  mixins: [searchFormMixin],

  methods: {
    resetStagedQuery() {
      this.stagedQuery = '';
    },
  },
};
</script>

<template>
  <div class="mega-menu-mobile__search-form">
    <a
      :aria-label="$t('Search.suggestions.ariaLabel')"
      class="mega-menu-mobile__focus-trap"
      role="button"
      aria-haspopup="true"
      href="#"
    ></a>

    <form @submit.prevent="search(true)">
      <div class="input-wrapper input-wrapper--offset-end input-wrapper--offset-start mega-menu-mobile__search-field">
        <div class="input-adornment input-adornment--start">
          <phx-icon icon="search" />
        </div>

        <input
          id="mega-menu-mobile-search-field"
          v-model="stagedQuery"
          name="keyword"
          type="search"
          class="input mega-menu-mobile__search-input input-wrapper__input"
          :placeholder="$t('Search.placeholder')"
          autocomplete="off"
          tabindex="-1"
          aria-hidden="true"
          @focus="resetQuery()"
        />

        <button
          id="mega-menu-mobile-search-clear"
          class="input-adornment input-adornment--end mega-menu-mobile__clear-button is-visible"
          type="button"
          :aria-label="$t('Search.suggestions.clear.ariaLabel')"
          @click="resetStagedQuery()"
        >
          <phx-icon icon="close" />
        </button>
      </div>
    </form>

    <button
      class="btn btn--link btn--sm mega-menu-mobile__cancel-button"
      :aria-label="$t('Search.suggestions.cancel.ariaLabel')"
    >
      <span class="btn__text">{{ $t('Form.cancel') }}</span>
    </button>
  </div>
</template>
