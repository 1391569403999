import { mapActions, mapState } from 'vuex';
import { parse as queryStringParse } from 'query-string';

export default {
  props: {
    action: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      stagedQuery: '',
    };
  },

  computed: {
    ...mapState('search', ['isResultsComponentPresent', 'query', 'isLoading']),
  },

  created() {
    this.setQueryFromQueryParams();
    window.addEventListener('popstate', this.setQueryFromQueryParams);
  },

  methods: {
    ...mapActions('search', ['changeCurrentTab', 'fetchSuggestions', 'setSearchAsYouTypeEndpoint', 'setQuery']),

    updateQuery(keyword) {
      this.setQuery(keyword);
      if (this.stagedQuery !== keyword) {
        this.stagedQuery = keyword;
      }
    },

    setQueryFromQueryParams() {
      const queryString = queryStringParse(window.location.search, {
        arrayFormat: 'bracket',
      });
      if (queryString.keyword && queryString.keyword !== this.query) {
        this.updateQuery(queryString.keyword);
      }
    },

    updateUrlWithoutReloading() {
      const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?keyword=${this.stagedQuery}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    },
  },
};
