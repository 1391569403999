<script>
import searchFormMixin from '@maison/mixins/searchFormMixin';
import { PhxIcon } from '@phx-styleguides/als';

export default {
  components: { PhxIcon },

  mixins: [searchFormMixin],

  props: {
    panelId: {
      type: String,
      default: '',
    },
  },

  computed: {
    dataTarget() {
      return this.panelId ? `#${this.panelId}` : null;
    },
  },
};
</script>

<template>
  <div class="navbar__search">
    <form id="search-nav-bar-form-field" class="form-field" @submit.prevent="search(true)">
      <label for="search-nav-bar" class="input-label form-field__label sr-only">
        {{ $t('Search.placeholder') }}
      </label>

      <div class="input-wrapper input-wrapper--offset-end">
        <input
          id="search-nav-bar"
          v-model="stagedQuery"
          name="keyword"
          type="search"
          class="input input-wrapper__input is-expanded"
          :placeholder="$t('Search.placeholder')"
          data-toggle="mega-menu"
          :data-target="dataTarget"
          autocomplete="off"
          autocorrect="off"
          aria-expanded="false"
          @focus="resetQuery(stagedQuery)"
        />
        <button class="input-adornment input-adornment--end" type="submit" tabindex="-1">
          <phx-icon icon="search" />
        </button>
      </div>
    </form>
  </div>
</template>
