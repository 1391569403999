import createVueApp from '@phoenix/createVueApp';
import MobileMenuSearchForm from '@maison/components/search/MobileMenuSearchForm.vue';
import NavSearchForm from '@maison/components/search/NavSearchForm.vue';
import SearchForm from '@maison/components/search/SearchForm.vue';

if (document.getElementById('phoenix-nav-search-form')) {
  const app = createVueApp({}, true);
  app.component('MobileMenuSearchForm', MobileMenuSearchForm);
  app.mount('#phoenix-mobile-menu-search-form');
}

if (document.getElementById('phoenix-nav-search-form')) {
  const app = createVueApp({}, true);
  app.component('NavSearchForm', NavSearchForm);
  app.mount('#phoenix-nav-search-form');
}

document.querySelectorAll('.phoenix-search-form').forEach((el) => {
  const app = createVueApp({}, true);
  app.component('SearchForm', SearchForm);
  app.mount(el);
});
