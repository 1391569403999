<script>
import SearchForm from '@phoenix/mixins/components/search/SearchForm';

export default {
  mixins: [SearchForm],

  props: {
    placeholder: {
      type: String,
      default: '',
    },
  },

  methods: {
    search() {
      if (this.isResultsComponentPresent) {
        this.updateUrlWithoutReloading();
        this.setQuery(this.stagedQuery);
      } else {
        window.location.href = `${this.action}?keyword=${this.stagedQuery}`;
      }
    },
  },
};
</script>

<template>
  <form @submit.prevent="search">
    <div class="flex flex-col gap-sm md:gap-0 md:flex-row">
      <input v-model="stagedQuery" :placeholder="placeholder" type="search" class="input" autocorrect="off" />

      <button type="submit" class="btn">
        <span class="btn__text">{{ $t('Search.submit') }}</span>
      </button>
    </div>
  </form>
</template>
