import * as types from '@phoenix/store/mutations-types';
import { mapActions, mapMutations } from 'vuex';

export default {
  props: {
    action: {
      type: String,
      default: '',
    },
    panelId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      inputTimeout: null,
      stagedQuery: '',
    };
  },

  watch: {
    stagedQuery(newValue) {
      if (newValue === '') {
        this.resetQuery();
      }

      clearTimeout(this.inputTimeout);
      this.inputTimeout = setTimeout(() => this.search(), 300);
    },
  },

  mounted() {
    this.setSearchAsYouTypeEndpoint('api.search.suggestions');
  },

  methods: {
    ...mapActions('search', ['fetchSuggestions', 'setQuery', 'setSearchAsYouTypeEndpoint']),
    ...mapMutations('search', [types.SEARCH_SET_STAGED_QUERY]),

    resetQuery(value = '') {
      this.setQuery(value);
    },

    async search(formSubmitted = false) {
      this[types.SEARCH_SET_STAGED_QUERY](this.stagedQuery);
      if (this.stagedQuery === '') {
        return;
      }

      if (formSubmitted) {
        window.location.href = `${this.action}?keyword=${this.stagedQuery}`;
      } else {
        await this.fetchSuggestions(this.stagedQuery);
      }
    },
  },
};
